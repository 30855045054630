<template>
  <div class="layout_common duration">
    <div class="main_box">
      <div class="main_box_con">
        <searchToChargeback
          compType="duration"
          :detailPageQuery="false"
          @search="search"
          @reset="search"
        />
        <div class="echart" v-loading="loading">
          <barEchart
            id1="duration_box"
            id2="duration_echart"
            :propData="echartData"
            :barColor="['#5470c6', '#fac858']"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import searchToChargeback from '@/views/data-center/components/searchToChargeback'
import barEchart from '@/views/data-center/components/agingBar'
import { chargebackDuration } from '@/api'
import { formatDate, diffDate } from '@/utils/common'
export default {
  components: {
    barEchart,
    searchToChargeback
  },
  data () {
    return {
      loading: false,
      isShow: false,
      storeId: '',
      storeName: '',
      startTime: '',
      endTime: '',
      timeType: 3,
      type: 1,
      level: '',
      cityId: '',
      chargebackCategory: '',
      chargebackType: '',
      reason: '',
      echartData: []
    }
  },
  mounted () {
    this.endTime = formatDate(new Date()).slice(0, 7)
    this.startTime = diffDate(-5, 'months', true).slice(0, 7)
    this.getList()
  },
  methods: {
    getList () {
      const params = {
        level: this.level,
        cityId: this.cityId,
        type: this.type,
        timeType: this.timeType,
        storeId: this.storeId,
        chargebackCategory: this.chargebackCategory,
        chargebackType: this.chargebackType,
        reason: this.reason,
        start: this.startTime,
        end: this.endTime
      }
      this.loading = true
      chargebackDuration(params).then(res => {
        this.loading = false
        const dataX = res.data.map(item => item.codeName)
        const value = res.data.map(item => item.amount)
        this.echartData = [
          {
            dataX,
            value
          }
        ]
      })
    },
    search (val) {
      this.curPage = 1
      const {
        timeType,
        startTime,
        endTime,
        level,
        cityId,
        storeId,
        type,
        durationType,
        chargebackCategory,
        chargebackType,
        reason
      } = val
      this.timeType = timeType
      this.startTime = startTime
      this.endTime = endTime
      this.type = type
      this.level = level
      this.cityId = cityId
      this.storeId = storeId
      this.durationType = durationType
      this.chargebackCategory = chargebackCategory
      this.chargebackType = chargebackType
      this.reason = reason
      this.getList()
    }
  }
}
</script>
<style lang="scss" scoped>
.duration {
  .echart {
    margin-top: 20px;
    height: calc(100% - 120px);
  }
}
</style>
